<template>
  <div>
    <div class="progress-section" :class="isFull ? '' : 'row'">
      <div
        v-for="(tag, index) in tags"
        :key="index"
        class="tag-progress"
        :class="isFull ? '' : 'col-md-6'"
      >
        <div class="tag-header">
          <div class="tag-title">
            <h3 class="mb-0" style="cursor: pointer;">
              {{ tag.question_tag_name }}
            </h3>
          </div>
          <div class="score-range">
            <el-tooltip
              effect="dark"
              placement="top"
              content="This is your score prediction if the entire test contains questions from this topic."
              :visible-arrow="false"
            >
              <span>
                Performance
                <i class="fas fa-question-circle"></i> :
                {{
                  getScoreRange(
                    tag.user_exam_correct_count,
                    tag.user_exam_question_count
                  )
                }}
              </span>
            </el-tooltip>
          </div>
        </div>
        <div class="Bar-box">
          <div class="tag-subtitle" :class="{ 'title-hide': showDetail }">
            <el-tooltip
              class="item"
              effect="dark"
              :content="$t('pageTitle.Your performance on this test')"
              placement="top"
              v-show="showDetail"
            >
              <span>
                {{ $t("pageTitle.This Test") }}
                <i class="fa fa-question-circle question-icon"></i>
                &nbsp;
              </span>
            </el-tooltip>
            <span
              v-html="
                getAccuracy(
                  tag.user_exam_correct_count,
                  tag.user_exam_question_count
                )
              "
            ></span>
          </div>
          <div class="progress-bars">
            <div class="progress-container">
              <el-progress
                style="width: 100%"
                :stroke-width="20"
                :show-text="false"
                :percentage="
                  getAvgProgress(
                    tag.user_exam_correct_count,
                    tag.user_exam_question_count
                  )
                "
              ></el-progress>
            </div>
          </div>
        </div>
        <div v-show="showDetail" class="Bar-box">
          <div class="tag-subtitle" :class="{ 'title-hide': showDetail }">
            <el-tooltip
              class="item"
              effect="dark"
              :content="$t('pageTitle.Your cumulative average')"
              placement="top"
            >
              <span>
                {{ $t("pageTitle.My Average") }}
                <i class="fa fa-question-circle question-icon"></i>
                &nbsp;
                <span
                  v-html="
                    getAvg(tag.user_correct_count, tag.user_answered_count)
                  "
                ></span>
              </span>
            </el-tooltip>
          </div>
          <div class="progress-bars">
            <div class="progress-container">
              <el-progress
                style="width: 100%"
                :stroke-width="20"
                :show-text="false"
                color="#409efe"
                :percentage="
                  getAvgProgress(
                    tag.user_correct_count,
                    tag.user_answered_count
                  )
                "
              ></el-progress>
            </div>
          </div>
        </div>
        <div v-show="showDetail" class="Bar-box">
          <div class="tag-subtitle" :class="{ 'title-hide': showDetail }">
            <el-tooltip
              class="item"
              effect="dark"
              :content="
                $t('pageTitle.Everyone’s average for this question type')
              "
              placement="top"
            >
              <span>
                {{ $t("pageTitle.Average") }}
                <i class="fa fa-question-circle question-icon"></i>
                &nbsp;
                <span
                  v-html="
                    getAvg(
                      tag.question_tag_correct_count,
                      tag.question_tag_answered_count
                    )
                  "
                ></span>
              </span>
            </el-tooltip>
          </div>
          <div class="progress-bars">
            <div class="progress-container">
              <el-progress
                style="width: 100%"
                :stroke-width="20"
                :show-text="false"
                color="#2c3e50"
                :percentage="
                  getAvgProgress(
                    tag.question_tag_correct_count,
                    tag.question_tag_answered_count
                  )
                "
              ></el-progress>
            </div>
          </div>
        </div>
        <div v-show="showDetail" class="mb-2">
          <b class="tag-subtitle">
            Questions:
          </b>
          <router-link
            :to="{
              name: testType + 'Explanations',
              params: {
                examId: examId,
                userExamId: userExamId
              },
              query: {
                sectionOrder: question.split('-')[0],
                questionOrder: question.split('-')[1]
              }
            }"
            v-for="(question, key) in orderTags(tag.question_orders)"
            :key="key"
          >
            <span
              :class="{
                'view-question': true,
                correct: question.split('-')[2] == 1,
                error: question.split('-')[2] == 0,
                empty: question.split('-')[2] == 2
              }"
            >
              <span v-if="testType !== 'ACT'"
                >{{ question.split("-")[0] }}-</span
              >{{ question.split("-")[1] }}
            </span>
          </router-link>
        </div>
        <!-- 添加递归组件用于渲染子标签 -->
        <div
          v-if="tag.children && tag.children.length > 0"
          class="text-primary toggle-button "
        >
          <div @click="toggleChildren(tag)">
            <span v-show="tag.isExpanded">
              <i class="fas fa-chevron-up"></i>
              &nbsp; Hide Skill/Knowledge Testing Point
            </span>
            <span v-show="!tag.isExpanded">
              <i class="fas fa-chevron-down"></i>
              &nbsp; View Skill/Knowledge Testing Point
            </span>
          </div>
          <div>
            <transition name="slide">
              <div
                v-if="tag.children && tag.children.length > 0 && tag.isExpanded"
                class="children-tags"
              >
                <Performance
                  section="Reading"
                  :tagType="tagType"
                  :tags="tag.children"
                  :examId="examId"
                  :userExamId="userExamId"
                  :testType="testType"
                  :showDetail="showDetail"
                  :isFull="true"
                />
              </div>
            </transition>
          </div>
        </div>
        <hr />
      </div>
      <div class="table-box pl-2 pr-2 mb-4" v-show="false">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th class="green-td" rowspan="2">
                {{ $t("pageTitle.Question Type") }}
              </th>
              <th class="green-td" rowspan="2" style="width:3rem">
                <div>
                  {{ $t("pageTitle.This Test") }}
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="$t('pageTitle.Your performance on this test')"
                    placement="top"
                  >
                    <span>
                      <i class="fa fa-question-circle question-icon"></i>
                    </span>
                  </el-tooltip>
                </div>
              </th>
              <th class="green-td" rowspan="2" style="width:3rem">
                <div>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="$t('pageTitle.Your cumulative average')"
                    placement="top"
                  >
                    <span>
                      {{ $t("pageTitle.My Average") }}
                      <i class="fa fa-question-circle question-icon"></i>
                    </span>
                  </el-tooltip>
                </div>
              </th>
              <th class="green-td" rowspan="2" style="width:3rem">
                <div>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="
                      $t('pageTitle.Everyone’s average for this question type')
                    "
                    placement="top"
                  >
                    <span>
                      {{ $t("pageTitle.Average") }}
                      <i class="fa fa-question-circle question-icon"></i>
                    </span>
                  </el-tooltip>
                </div>
              </th>
              <th class="green-td" rowspan="2" style="width: 20rem;">
                Questions
              </th>
            </tr>
            <tr></tr>
          </thead>
          <tbody>
            <tr v-for="(tag, index) in tags" :key="index">
              <td class="text-left" style="width:150px">
                <el-tag type="success" size="small" class="ivy-tag">
                  <b>
                    {{ tag.question_tag_name }}
                  </b>
                </el-tag>
              </td>
              <td
                style="width:150px"
                v-html="
                  getAccuracy(
                    tag.user_exam_correct_count,
                    tag.user_exam_question_count
                  )
                "
              ></td>
              <!-- myAccuracy -->
              <td
                style="width:100px"
                v-html="getAvg(tag.user_correct_count, tag.user_answered_count)"
              ></td>
              <td
                style="width:100px"
                v-html="
                  getAvg(
                    tag.question_tag_correct_count,
                    tag.question_tag_answered_count
                  )
                "
              ></td>
              <td class="text-left" style="padding:5px;width: 20rem;">
                <router-link
                  :to="{
                    name: testType + 'Explanations',
                    params: {
                      examId: examId,
                      userExamId: userExamId
                    },
                    query: {
                      sectionOrder: question.split('-')[0],
                      questionOrder: question.split('-')[1]
                    }
                  }"
                  v-for="(question, key) in orderTags(tag.question_orders)"
                  :key="key"
                >
                  <span
                    :class="{
                      'view-question': true,
                      correct: question.split('-')[2] == 1,
                      error: question.split('-')[2] == 0,
                      empty: question.split('-')[2] == 2
                    }"
                  >
                    <span v-if="testType !== 'ACT'"
                      >{{ question.split("-")[0] }}-</span
                    >{{ question.split("-")[1] }}
                  </span>
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { Decimal } from "decimal.js";
import echarts from "echarts";
import _ from "lodash";

export default {
  name: "Performance",
  components: {},

  mixins: [],

  props: [
    "section",
    "tags",
    "tagType",
    "examId",
    "userExamId",
    "testType",
    "showDetail",
    "isFull"
  ],
  data() {
    return {
      charts: null
    };
  },
  computed: {},
  watch: {},

  mounted() {},

  methods: {
    toggleChildren(tag) {
      this.$set(tag, "isExpanded", !tag.isExpanded);
    },
    orderTags(tags) {
      return _.sortBy(tags, [
        tag => Number(tag.split("-")[0]),
        tag => Number(tag.split("-")[1])
      ]);
    },
    getPer(correctQuestionsCount, questionsCount) {
      let correctCount = correctQuestionsCount;
      let allCount = questionsCount;
      let percent = Math.round((correctCount / allCount) * 100);
      if (percent > 100) {
        percent = 100;
      }
      if (correctQuestionsCount >= 0 && questionsCount >= 0) {
        switch (this.section) {
          case "Reading":
            return percent;
          case "Listening":
            return percent;
          default:
        }
      } else {
        return 0;
      }
    },
    getSubScoreAverage(count, score) {
      const convertScoreToLevel = score => {
        if (score === 5) return "A+";
        const scoreMapLevels = [
          { level: "E", min: 1, max: 1.2 },
          { level: "E+", min: 1.2, max: 1.5 },
          { level: "D-", min: 1.5, max: 1.8 },
          { level: "D", min: 1.8, max: 2.2 },
          { level: "D+", min: 2.2, max: 2.5 },
          { level: "C-", min: 2.5, max: 2.8 },
          { level: "C", min: 2.8, max: 3.2 },
          { level: "C+", min: 3.2, max: 3.5 },
          { level: "B-", min: 3.5, max: 3.8 },
          { level: "B", min: 3.8, max: 4.2 },
          { level: "B+", min: 4.2, max: 4.5 },
          { level: "A-", min: 4.5, max: 4.8 },
          { level: "A+", min: 4.8, max: 5 }
        ];
        const targetLevel = scoreMapLevels.find(
          ({ min, max }) => min <= score && score < max
        );
        if (targetLevel) {
          return targetLevel.level;
        } else {
          return "";
        }
      };
      if (count === 0) return "-";
      return convertScoreToLevel(Number(Decimal.div(score, count)));
    },
    getAverageScore(exam_count, question_count) {
      let maxScore = null;
      switch (this.section) {
        case "Speaking":
          maxScore = this.speakingMaxScore;
          break;
        case "Writing_independent":
        case "Writing_integrated":
          maxScore = this.writingMaxScore;
          break;
        default:
      }
      if (exam_count > 0 && question_count > 0) {
        let score = (exam_count / question_count).toFixed(2);
        return `<b class="text-success">${Number(score)}/${maxScore} </b> `;
      } else {
        return "<i class='fas fa-lock'></i>";
      }
    },
    getAccuracy(correctQuestionsCount, questionsCount) {
      if (questionsCount > 0) {
        let percent = Math.round(
          (correctQuestionsCount / questionsCount) * 100
        );
        if (percent > 100) {
          percent = 100;
        }
        const displayAccuracy = `${correctQuestionsCount}/${questionsCount}`;
        if (correctQuestionsCount >= 0 && questionsCount >= 0) {
          if (percent <= 50) {
            return `<b class="text-danger">${displayAccuracy} (${percent}%)</b> `;
          } else {
            return `<b class="text-success">${displayAccuracy} (${percent}%)</b> `;
          }
        } else {
          return "<i class='fas fa-lock'></i>";
        }
      } else {
        return "-";
      }
    },
    getAvg(correctQuestionsCount, questionsCount) {
      if (questionsCount > 0) {
        let percent = Math.round(
          (correctQuestionsCount / questionsCount) * 100
        );
        if (percent > 100) {
          percent = 100;
        }
        if (correctQuestionsCount >= 0 && questionsCount > 0) {
          if (percent <= 50) {
            return `<b class="text-danger">${percent}%</b> `;
          } else {
            return `<b class="text-success">${percent}%</b> `;
          }
        } else {
          return "<b class='text-danger'>0%</b> ";
        }
      } else {
        return "-";
      }
    },
    getAvgProgress(correctQuestionsCount, questionsCount) {
      let percent = Math.round((correctQuestionsCount / questionsCount) * 100);
      if (percent > 100) {
        percent = 100;
      }
      return percent;
    },
    getScoreRange(correct, total) {
      if (total > 0) {
        if (this.testType === "SAT") {
          const score = Math.round((correct / total) * 100);
          if (score >= 95) return "790-800";
          if (score >= 90) return "780-790";
          if (score >= 85) return "760-780";
          if (score >= 80) return "740-760";
          if (score >= 75) return "720-740";
          if (score >= 70) return "700-720";
          if (score >= 65) return "680-700";
          if (score >= 60) return "660-680";
          if (score >= 55) return "640-660";
          if (score >= 50) return "620-640";
          if (score >= 45) return "600-620";
          if (score >= 40) return "580-600";
          if (score >= 35) return "560-580";
          if (score >= 30) return "540-560";
          if (score >= 25) return "520-540";
          if (score >= 20) return "500-520";
          if (score >= 15) return "480-500";
          if (score >= 10) return "460-480";
          if (score >= 5) return "440-460";
          return "400-440";
        } else if (this.testType === "ACT") {
          const score = Math.round((correct / total) * 100);
          if (score >= 95) return "16";
          if (score >= 90) return "15";
          if (score >= 85) return "14";
          if (score >= 80) return "13";
          if (score >= 75) return "12";
          if (score >= 70) return "11";
          if (score >= 65) return "10";
          if (score >= 60) return "9";
          if (score >= 55) return "8";
          if (score >= 50) return "7";
          if (score >= 45) return "6";
          if (score >= 40) return "5";
          if (score >= 35) return "4";
          if (score >= 30) return "3";
          if (score >= 25) return "2";
          if (score >= 20) return "1";
          if (score >= 15) return "0-1";
          if (score >= 10) return "0-1";
          if (score >= 5) return "0-1";
          return "0-1";
        }
      } else {
        return "-";
      }
    }
  }
};
</script>

<style scoped>
.Bar-box {
  display: flex;
  align-items: center;
}
.table-box {
  width: 100%;
  overflow: auto;
}

.title {
  color: #494949;
  font-size: 20px;
  border: 0;
  padding: 0;
  border-left: 4px solid var(--themeColor);
  padding-left: 15px;
  /* line-height: 16px; */
  margin-bottom: 20px;
}
.performance {
  background-color: #f8f9fa;
  padding: 24px;
  border-radius: 8px;
}
.table {
  background-color: white;
  margin: 0;
  border-radius: 5px;
}
::v-deep .view-question {
  display: inline-block;
  height: 30px;
  min-width: 30px;
  line-height: 30px;
  text-align: center;
  font-weight: 700;
  font-size: 1rem;
  border-radius: 5px;
  margin: 5px;
  padding: 0 5px;
}
::v-deep .correct {
  /* border: 1px solid var(--themeColor); */
}
.view-question.correct {
  /* border: 0.1rem solid #d4edda; */
  cursor: pointer;
  background-color: #d4edda;
  color: #212529 !important;
}
.view-question.error {
  /* border: 0.1rem solid #ffdadd; */
  cursor: pointer;
  background-color: #ffdadd;
  color: #212529 !important;
}

.view-question.empty {
  /* border: 0.1rem solid #ffdadd; */
  cursor: pointer;
  background-color: #ebeef5;
  color: #212529 !important;
}

.performanceChart-box {
  width: 100%;
  overflow: auto;
}
.performanceChart {
  width: 100%;
  height: 500px;
}
@media screen and (max-width: 1200px) {
  .performanceChart-box {
    width: 100%;
    overflow: auto;
  }
  .performance {
    margin-top: 2rem;
    padding: 10px;
  }
}

.tag-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 10px;
}

.tag-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tag-title h3 {
  margin: 0 0 8px 0;
  font-size: 1rem;
  font-weight: 600;
  color: #2c3e50;
  display: flex;
  align-items: center;
}

.tag-subtitle {
  color: #6c757d;
  font-size: 0.9rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
  cursor: pointer;
  width: 7rem;
}
.tag-subtitle.title-hide {
  width: 12rem;
}

/* .tag-subtitle span {
  margin-right: 16px;
} */

.performance-text {
  font-weight: 500;
}

.score-range {
  font-size: 0.8rem;
  color: #2c3e50;
  font-weight: 500;
  padding: 3px 10px;
  background-color: #f8f9fa;
  border-radius: 1px;
  display: inline-block;
  cursor: pointer;
}

.progress-bars {
  flex: 1;
  margin-bottom: 8px;
}

.progress-container {
  display: flex;
  gap: 2px;
}

.progress-block {
  flex: 1;
  height: 1.1rem;
  border-radius: 2px;
  transition: all 0.3s ease;
}

.progress-block.filled-test {
  background-color: #42a16a;
  border: 1px solid #42a16a;
}

.progress-block.filled {
  background-color: #2c3e50;
  border: 1px solid #2c3e50;
}
.progress-block.filled-all {
  background-color: #409efe;
  border: 1px solid #409efe;
}

.progress-block.empty {
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.toggle-button {
  align-items: center;
  font-size: 0.9rem;
  gap: 8px;
  cursor: pointer;
  padding: 0.6rem 10px;
  border-radius: 6px;
  transition: all 0.2s ease;
  background-color: #f8f9fa;
  color: #2c3e50;
  font-weight: 500;
}

.toggle-button:hover {
  transform: translateY(-1px);
}

.toggle-button i {
  transition: transform 0.3s ease;
  color: #6c757d;
}

.children-tags {
  position: relative;
  padding: 0.5rem 0 0.5rem 1.5rem;
}

.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s ease;
  max-height: 1000px;
  opacity: 1;
  overflow: hidden;
}

.slide-enter-from,
.slide-leave-to {
  max-height: 0;
  opacity: 0;
}
.table td {
  width: 150px !important;
  min-width: 150px;
}
.table td:last-child {
  width: 600px !important; /* 可以根据需要调整这个值 */
  min-width: 600px; /* 确保最小宽度 */
}
@media (max-width: 768px) {
  .performance {
    padding: 16px;
  }
  .progress-block {
    height: 1rem;
  }
  ::v-deep .view-question {
    height: 24px;
    min-width: 24px;
    line-height: 24px;
    font-size: 0.8rem;
    margin: 2px;
    padding: 0 3px;
  }
  .table-box {
    padding-left: 0;
    padding-right: 0;
  }
  .table td {
    width: 100px !important;
    min-width: 120px;
  }
  /* 仅调整最后一列 */
  .table td:last-child {
    width: 300px !important; /* 可以根据需要调整这个值 */
    min-width: 300px; /* 确保最小宽度 */
  }
}
</style>
